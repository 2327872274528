import React from 'react'
import ContactForm from '../ContactForm'

function Contact() {
  return (
    <>
        <ContactForm />
    </>
  )
}

export default Contact