import React from 'react';
import ImageSlider from './ImageSlider'
import './AboutSection.css'; // Importing the CSS file


const img1 = "/images/anthony.jpg";
const img2 = "/images/brian.jpg";
const IMAGES = [
  {url: img1, alt: "Photo One"},
  {url: img2, alt: "Photo Two"}
]

const AboutSection = () => {
  return (
    <div className="about-container">
      <h1>About</h1>
      <div className="about-section">
        <div className="about-text">
          <p>
            AB Entertainment is an innovative independent management, artist development, and publishing company founded by Anthony Smith and Brian Thomas. Rooted in strong personal and professional relationships with our artists and songwriters. Our mission is to realize their musical vision and cultivate enduring, sustainable careers. We specialize in artist development, touring, events, management, and brand development.
          </p>
        </div>
        <div className="about-image"
          style={{maxWidth:"500px",
          width: "100%",
          
          aspectRatio: "4/5",
          }}
          >
          <ImageSlider imageUrls={IMAGES} />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
