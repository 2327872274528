// Cards.js
import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
      <h1>Artists</h1>
      <div className='cards__container'>
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/jimmy-1.jpg"
              text="Jimmy Harris"
              path='/artist/jimmy'
              instagramUrl="https://www.instagram.com/jimmyharrismusic/?utm_source=ig_web_button_share_sheet"
              youtubeUrl="https://www.youtube.com/channel/UC3LsL-oYMoBMtLJzL7aljJA"
              spotifyUrl="https://open.spotify.com/artist/4jApd0uA3JzEUHOJ2qmuh8"
              appleMusicUrl="https://music.apple.com/us/artist/jimmy-harris/1358666"
            />
            <CardItem
              src="images/nick-1.jpg"
              text="Nick Stone"
              path='/artist/nick'
              instagramUrl="https://www.instagram.com/nickstonemusic/?utm_source=ig_web_button_share_sheet"
              youtubeUrl="https://www.youtube.com/@nickstoneofficial/"
              spotifyUrl="https://open.spotify.com/artist/5P6xNhLL7fqK18eoDh2aWb"
              appleMusicUrl="https://music.apple.com/us/artist/nick-stone/1558572500"
            />
            <CardItem
              src="images/leah-1.jpg"
              text="Leah Turner"
              path='/artist/leah'
              instagramUrl="https://www.instagram.com/leahturnermusic/?utm_source=ig_web_button_share_sheet"
              youtubeUrl="https://www.youtube.com/LeahTurnerMusic"
              spotifyUrl="https://open.spotify.com/artist/04hpJHF9uqnRNDpjULw0zh?autoplay=true"
              appleMusicUrl="https://music.apple.com/us/artist/leah-turner/279681410"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
