import '../../App.css';
import React from 'react'
import HeroSection from '../HeroSection'

function Home() {
  return (
    <>
        <HeroSection />
    </>
  )
}

export default Home