import React from 'react';
import './Artist.css'; // Importing the shared CSS file

const NickInfo = () => {
  return (
    <div className="artist-container">
      <img src="/images/nick-1.jpg" alt="Nick Stone" />
      <div className="bio">
        <h2>Nick Stone</h2>
        <p>
          Born in the back room of a party house, the Nick Stone Band was raised on a hard diet of college culture, good music, and Busch Light. In its infant stages, the band cut their teeth the old fashioned way, burning down every sticky-staged bar in the Carolinas. Realizing the region was due for a new sound, the band started to craft a sound that has flavors of country, southern rock, boogie, and island music. With an original catalog of songs that are made for the front porch, the band has tailored their music around bringing that porch pickin’ sound to the stage, adding some additional seasoning with screaming Les Paul, gospel organ and thumping bass lines.
        </p>
        <p>
          <strong>List of accomplishments:</strong><br />
          - 1,200,000+ views on YouTube and counting<br />
          - 200,000+ streams on Apple Music and Spotify<br />
          - 150+ full band shows<br />
          - 16,000+ followers across all platforms<br />
          - Opened shows for Riley Green, Chase Wright, and Tracie Lynn
        </p>
      </div>
    </div>
  );
};

export default NickInfo;