import React from 'react'
import LeahInfo from '../LeahInfo'

function Leah() {
  return (
    <>
        <LeahInfo/>
    </>
  )
}

export default Leah