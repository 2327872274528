import React, {useEffect} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import About from './components/pages/About';
import Roster from './components/pages/Roster';
import Contact from './components/pages/Contact';
import News from './components/pages/News';
import Jimmy from './components/pages/Jimmy';
import Leah from './components/pages/Leah';
import Nick from './components/pages/Nick';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Router>
        <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/about" element={<About/>}></Route>
            <Route path="/news" element={<News/>}></Route>
            <Route path="/artist" element={<Roster/>}></Route>
            <Route path="/artist/jimmy" element={<Jimmy/>}></Route>
            <Route path="/artist/leah" element={<Leah/>}></Route>
            <Route path="/artist/nick" element={<Nick/>}></Route>
            <Route path="/contact" element={<Contact/>}></Route>
          </Routes>
          <Footer />
      </Router>
    </>
  );
}

export default App;
