import React from 'react'
import NickInfo from '../NickInfo'

function Nick() {
  return (
    <>
        <NickInfo/>
    </>
  )
}

export default Nick