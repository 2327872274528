import React from 'react'
import '../../App.js'
import AboutSection from '../AboutSection'

function About() {
  return (
    <>
        <AboutSection />
    </>
  )
}

export default About