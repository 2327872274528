import React from 'react'
import NewsArticles from '../NewsArticles';


function News() {
  return (
    <>
        <NewsArticles />
    </>
  )
}

export default News