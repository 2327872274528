import React from 'react';
import './Artist.css'; // Importing the CSS file

const JimmyInfo = () => {
  return (
    <div className="artist-container">
      <img src="/images/jimmy-1.jpg" alt="Jimmy Harris" />
      <div className="bio">
        <h2>Jimmy Harris</h2>
        <p>
          Jimmy Harris is an established artist from Kansas City, who is a well-versed guitar player with naturally obtained talents from his family’s musical background. He discovered his passion for music at the age of six and began performing in clubs at just twelve years old. By seventeen, he was the lead singer of his band, honing his versatility as a musician.
        </p>
        <p>
          Known for drawing large regional crowds and possessing an extensive knowledge of music across generations, Jimmy proves with every show that he is ready to take on a larger market. With that, he has teamed up with Nashville veteran producers: Matt Rovey (Alan Jackson, Zac Brown, Uncle Kracker, Billy Currington, etc.) and Warren Rhoades (Chief Engineer at Sound Stage Studio, Fantasy Studios).
        </p>
        <p>
          Influenced by legends like Johnny Cash, Hank Williams, Lynyrd Skynyrd, and Stevie Ray Vaughan, Jimmy infuses their essence into his new songs, co-written with Nashville's top songwriters. His music offers a unique style that appeals to all ages.
        </p>
        <p>
          As an independent solo artist, Jimmy Harris keeps the tradition of storytelling alive through prolific melodies, promising a fulfilling experience for all listeners.
        </p>
      </div>
    </div>
  );
};

export default JimmyInfo;
