import React, { useState, useEffect, useRef } from 'react';
import './ImageSlider.css';

function ImageSlider({ imageUrls }) {
  const [imageIndex, setImageIndex] = useState(0);
  const [isAutoSliding, setIsAutoSliding] = useState(true);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isAutoSliding) {
      timerRef.current = setInterval(() => {
        setImageIndex(prevIndex => (prevIndex + 1) % imageUrls.length);
      }, 5000); // Auto slide every 5 seconds
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isAutoSliding, imageUrls.length]);

  const showPrevImage = () => {
    setImageIndex(prevIndex =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
    setIsAutoSliding(false); // Stop automatic sliding when user interacts
  };

  const showNextImage = () => {
    setImageIndex(prevIndex => (prevIndex + 1) % imageUrls.length);
    setIsAutoSliding(false); // Stop automatic sliding when user interacts
  };

  const handleManualSlide = () => {
    setIsAutoSliding(false); // Stop automatic sliding when user interacts
  };

  const names = ["Anthony", "Brian"];

  return (
    <section aria-label="Image Slider" className="img-slider-container">
      <div className="img-slider-section" onMouseDown={handleManualSlide}>
        {imageUrls.map(({ url, alt }, index) => (
          <div
            key={url}
            className="img-slider-item"
            style={{ transform: `translateX(-${100 * imageIndex}%)` }}
          >
            <img
              src={url}
              alt={alt}
              aria-hidden={imageIndex !== index}
              className="img-slider-img"
            />
            {imageIndex === index && (
              <div className="img-slider-name">
                {names[index]}
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        onClick={showPrevImage}
        className="img-slider-btn"
        style={{ left: 0 }}
        aria-label="View Previous Image"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </button>
      <button
        onClick={showNextImage}
        className="img-slider-btn"
        style={{ right: 0 }}
        aria-label="View Next Image"
      >
        <i className="fa-solid fa-arrow-right"></i>
      </button>
      <div className="img-slider-carousel">
        {imageUrls.map((_, index) => (
          <button
            key={index}
            className="img-slider-carousel-btn"
            onClick={() => {
              setImageIndex(index);
              handleManualSlide(); // User interaction detected
            }}
            aria-label={`View Image ${index + 1}`}
          >
            {index === imageIndex ? (
              <i className="fa-solid fa-circle-check"></i>
            ) : (
              <i className="fa-regular fa-circle"></i>
            )}
          </button>
        ))}
      </div>
    </section>
  );
}

export default ImageSlider;
