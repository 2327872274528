import React from 'react';
import './Artist.css'; // Importing the shared CSS file

const LeahInfo = () => {
  return (
    <div className="artist-container">
      <img src="/images/leah-1.jpg" alt="Leah Turner" />
      <div className="bio">
        <h2>Leah Turner</h2>
        <p>
          Leah Galvan Turner is the embodiment of a country song - a true fusion of American love and Latin passion. Born to Don Turner, a world champion rodeo star, and Susaña Galvan, a first-generation Mexican American jewelry designer, artist, and ballet dancer, Leah's story began with a blend of cultures. From this American love story came a fiery Turner, who first sat down to a piano at 3 years old and has since felt called to blend her Latin roots with the country music she creates - it was inevitable that all roads would eventually lead to Nashville.
        </p>
        <p>
          First discovered by GRAMMY Award-winning artist Kenny Loggins, who told her to pack up her stuff and move to L.A., Turner has since partnered with multiple GRAMMY Award-winning producers Humberto Gatica and David Foster, and is now further honing her sound with industry veteran Brett Boyett at the production helm. Touting an impressive touring history, Turner has shared the stage with country music stars like Brad Paisley, Kenny Chesney, and Rascal Flatts, leaving an indelible mark on the country music scene.
        </p>
        <p>
          In 2022, Turner released her EP Lost in Translation, allowing her to fully embrace her heritage, as exemplified by the standout single "Once Upon a Time in Mexico." With her latest single, "South Of The Border" (featuring chart-topping country artist Jerrod Niemann), and her forthcoming EP, set to release in the spring of 2024, she continues to break barriers in country music. Turner proudly holds the title of the highest-charted solo female Mexicana in country music, and is the first Mexicana to have her attire featured in the "Power of Women in Country Music'' GRAMMY® exhibit. Her journey is a testament to the enduring love affair between country music and Hispanic culture - a story that's straight out of a classic country song, uniting two cultures in a genre she loves.
        </p>
      </div>
    </div>
  );
};

export default LeahInfo;
