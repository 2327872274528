import React from 'react'
import JimmyInfo from '../JimmyInfo'

function Jimmy() {
  return (
    <>
        <JimmyInfo/>
    </>
  )
}

export default Jimmy