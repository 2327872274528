// NewsItem.js
import React from 'react';
import './NewsItem.css'; // Import CSS for NewsItem

function NewsItem(props) {
  const handleCardClick = () => {
    // Navigate to the news article page only if the click event wasn't triggered by social media icons
    window.location.href = props.path;
  };

  return (
    <li className="news__item" onClick={handleCardClick}>
      <div className="news__item__link">
        <figure className="news__item__pic-wrap">
          <img src={props.src} alt="News" className="news__item__img" />
        </figure>
        <div className="news__item__info">
          <h5 className="news__item__text">{props.text}</h5>
        </div>
      </div>
    </li>
  );
}

export default NewsItem;
