// CardItem.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faSpotify, faApple } from '@fortawesome/free-brands-svg-icons';
import './CardItem.css'; // Import CSS for CardItem

function CardItem(props) {
  const handleInstagramClick = (event) => {
    event.stopPropagation(); // Stop the event from bubbling up to parent elements
    window.open(props.instagramUrl, '_blank'); // Open Instagram URL in a new tab
  };

  const handleYoutubeClick = (event) => {
    event.stopPropagation(); // Stop the event from bubbling up to parent elements
    window.open(props.youtubeUrl, '_blank'); // Open YouTube URL in a new tab
  };

  const handleSpotifyClick = (event) => {
    event.stopPropagation(); // Stop the event from bubbling up to parent elements
    window.open(props.spotifyUrl, '_blank'); // Open Spotify URL in a new tab
  };

  const handleAppleMusicClick = (event) => {
    event.stopPropagation(); // Stop the event from bubbling up to parent elements
    window.open(props.appleMusicUrl, '_blank'); // Open Apple Music URL in a new tab
  };

  const handleCardClick = () => {
    // Navigate to the artist's page only if the click event wasn't triggered by social media icons
    window.location.href = props.path;
  };

  return (
    <li className="cards__item" onClick={handleCardClick}>
      <div className="cards__item__link">
        <figure className="cards__item__pic-wrap">
          <img src={props.src} alt="Artist" className="cards__item__img" />
        </figure>
        <div className="cards__item__info">
          <h5 className="cards__item__text">{props.text}</h5>
          {/* Social media icons */}
          <div className="cards__item__social-icons">
            {/* Instagram icon */}
            <div className="social-icon" onClick={handleInstagramClick}>
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </div>
            {/* YouTube icon */}
            <div className="social-icon" onClick={handleYoutubeClick}>
              <FontAwesomeIcon icon={faYoutube} size="lg" />
            </div>
            {/* Spotify icon */}
            <div className="social-icon" onClick={handleSpotifyClick}>
              <FontAwesomeIcon icon={faSpotify} size="lg" />
            </div>
            {/* Apple Music icon */}
            <div className="social-icon" onClick={handleAppleMusicClick}>
              <FontAwesomeIcon icon={faApple} size="lg" />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default CardItem;
