import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className="hero-container">
      <div className="hero-section">
      <video className="hero-video" autoPlay loop muted playsInline controls={false}>
        <source src="/hero-video.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

export default HeroSection