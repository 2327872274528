// News.js
import React from 'react';
import NewsItem from './NewsItem'
import './NewsArticles.css'; // Import CSS for News

function NewsArticles() {
  return (
    <div className='news'>
      <h1>News</h1>
      <div className='news__container'>
        <div className="news__wrapper">
          <ul className="news__items">
            <NewsItem
                src="images/leah-turner-news-1.webp"
                text="Leah Turner is appearing at CMA fest on the CMA Close Up Stage inside Fan Fair X Friday 6/7 with Frank Ray and more 'Latin Roots' to support the CMA Foundation & their mission to shape the next generation through music education, alongside the incredible folks at Equis Culture! Click to see tickets & details!"
                path="https://CMAfest.com/FanFairX"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NewsArticles;
