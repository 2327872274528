import React from 'react'
import Cards from '../Cards'

function Clients() {
  return (
    <>
      <Cards />
    </>
  )
}

export default Clients